import * as React from 'react'
import { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Autoplay } from 'swiper';
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'

const ModelroomPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <main className='mainModelroom'>
      {/* CONTENTS */}

      <ComMain
        ttl="MODEL ROOM"
        subttl="モデルルーム"
        caption="LIVING DINING KITCHEN"
      >
        <StaticImage
          src="../assets/images/modelroom/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/modelroom/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>


      <section className="sec-gallery">
        <div className="c-content">

          <div className="main-wrap">
            <Swiper
              modules={[Thumbs, Autoplay]}
              loop={true}
              slidesPerView={1}
              thumbs={{ swiper: thumbsSwiper }}
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              className='main-box'
            >
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_01.jpg" alt="" />
                  <p className="cap cap1">LIVING DINING KITCHEN</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_02.jpg" alt="" />
                  <p className="cap cap2">LIVING DINING</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_03.jpg" alt="" />
                  <p className="cap cap3">LIVING DINING KITCHEN／BEDROOM 3</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_04.jpg" alt="" />
                  <p className="cap cap4">MASTER BEDROOM</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_05.jpg" alt="" />
                  <p className="cap cap5">BEDROOM 2</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_06.jpg" alt="" />
                  <p className="cap cap6">BEDROOM 3</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_07.jpg" alt="" />
                  <p className="cap cap7">ENTRANCE/CORRIDOR</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img-box">
                  <StaticImage src="../assets/images/modelroom/img_08.jpg" alt="" />
                  <p className="cap cap8">BALCONY</p>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>

          <div className="thumbs-wrap">
            <Swiper
              onSwiper={setThumbsSwiper}
              modules={[Thumbs]}
              slidesPerView={8}
              watchSlidesProgress={true}
              className='thumbs-box'
            >
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_01.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_02.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_03.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_04.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_05.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_06.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_07.jpg" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <StaticImage src="../assets/images/modelroom/thum_08.jpg" alt="" />
              </SwiperSlide>

            </Swiper>
          </div>

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className='c-note'>
            ※掲載の写真はモデルルームAタイプを撮影（2022年3月撮影）したものに⼀部CG処理をしたものです。家具、備品等は配置例を示したもので価格には含まれません。また、オプション、設計変更仕様が含まれています。予めご了承ください。
            </p>
          </div>
        </div>
      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default ModelroomPage
